<template>
  <div class="products-widget">
    <div class="header">Products</div>

    <div class="content" style="max-height: 300px; overflow: auto">
      <div v-if="!products.length" class="m-3 font-italic text-secondary">
        Keine verfügbaren Produkte.
      </div>

      <div class="item" v-for="(product, i) in products" :key="i">
        <div class="inner">
          <div class="left">
            <div class="image">
              <img :src="product.images | resolveImagesJson('70')" />
            </div>

            <div class="details ml-3">
              <router-link
                class="title"
                :to="{
                  name: 'ProductsProduct',
                  params: { company: companyId, product: product.id },
                }"
              >
                {{ product.name }}
              </router-link>
              <div class="quantity">{{ product.quantity }} übrig</div>
            </div>
          </div>

          <div class="right">
            <div class="price">{{ product.price | EUR }}</div>
            <div class="checkbox">
              <CheckboxElement
                :value="isProductInCart(product)"
                :read-only="!+product.quantity"
                @input="handleProductSelect($event, product)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedItems: [],
    };
  },

  props: {
    companyId: {
      type: [Number, String],
      required: true,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    isProductInCart(product) {
      const result = !!this.$store.getters.cartItems.find(
        i => i.item.id == product.id
      );

      if (result) {
        this.handleProductSelect(true, product);
      }

      return result;
    },
    handleProductSelect(event, product) {
      if (event) {
        if (this.selectedItems.find(val => val.id == product.id)) {
          return;
        }

        this.selectedItems.push({
          id: product.id,
          qnt: 1,
        });
      } else {
        this.selectedItems = this.selectedItems.filter(
          val => val.id != product.id
        );
      }

      this.$emit('input', this.selectedItems);
    },
  },
};
</script>

<style lang="scss" scoped>
.products-widget {
  background: #f6f6f6;
  border-radius: 10px;
  padding-top: 20px;
}
.header {
  font-weight: bold;
  font-size: 20px;
  color: #e58413;
  text-align: center;
}
.item {
  .inner {
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
  }
  border-bottom: 1px solid #d4d4d4;
}
.item:last-child {
  border-bottom: none;
}
.left,
.right {
  display: flex;
  align-items: center;
}
.image img {
  border-radius: 5px;
  width: 70px;
  height: 70px;
}
.price {
  margin-right: 10px;
  font-weight: bold;
  font-size: 20px;
  color: #2b2b2b;
}
.details {
  .title {
    font-weight: bold;
    font-size: 20px;
    color: #e58413;
  }
  .quantity {
    margin-top: 10px;
    font-weight: normal;
    font-size: 14px;
    color: #bfbfbf;
  }
}
.actions {
  text-align: right;
  padding: 20px;
  div {
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    color: #e58413;
  }
}
</style>
