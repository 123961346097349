<template>
  <VsaList>
    <!-- Description -->
    <VsaItem>
      <VsaHeading>{{ $t('enduser.ads.description') }}</VsaHeading>
      <VsaIcon>
        <CarrotDownWide :color="'#999999'" :rotate="'-90deg'" />
      </VsaIcon>
      <VsaContent>
        <span v-if="company.description">{{ company.description }}</span>
        <span v-else class="font-italic text-secondary">
          Keine Beschreibung.
        </span>
      </VsaContent>
    </VsaItem>

    <!-- Working hours -->
    <VsaItem>
      <VsaHeading>{{ $t('enduser.services.offnungszeiten') }}</VsaHeading>
      <VsaIcon>
        <CarrotDownWide :color="'#999999'" :rotate="'-90deg'" />
      </VsaIcon>
      <VsaContent v-if="company.workingHours">
        <div class="row working-hours-table">
          <div class="col-auto">
            <label>Tag</label>
            <div
              v-for="(values, day) in JSON.parse(company.workingHours)"
              :key="day"
              class="table-row"
            >
              {{ $t(`serviceProvider.settingsCompany.${day.toLowerCase()}`) }}
            </div>
          </div>
          <div class="col-auto">
            <label>Uhrzeit</label>
            <div
              v-for="(values, day) in JSON.parse(company.workingHours)"
              :key="day"
              class="table-row"
            >
              <template v-if="values.hours.from || values.hours.to">
                <span class="start-time">
                  {{ values.hours.from }}
                </span>
                <span class="separator">-</span>
                <span class="end-time">
                  {{ values.hours.to }}
                </span>
              </template>
              <template v-else>
                <div class="off">
                  {{ $t(`serviceProvider.settingsCompany.notWorking`) }}
                </div>
              </template>
            </div>
          </div>
          <div class="col">
            <label>Pause</label>
            <div
              v-for="(values, day) in JSON.parse(company.workingHours)"
              :key="day"
              class="table-row"
            >
              <template v-if="values.break.from || values.break.to">
                <span class="start-time">
                  {{ values.break.from }}
                </span>
                <span class="separator">-</span>
                <span class="end-time">
                  {{ values.break.to }}
                </span>
              </template>
              <template v-else>
                <div class="off">-</div>
              </template>
            </div>
          </div>
        </div>
      </VsaContent>
    </VsaItem>

    <!-- Contact info -->
    <VsaItem>
      <VsaHeading>{{ $t('enduser.products.contact') }}</VsaHeading>
      <VsaIcon>
        <CarrotDownWide :color="'#999999'" :rotate="'-90deg'" />
      </VsaIcon>
      <VsaContent>
        <div class="contact-item" v-if="company.email">
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.61093 0.125C2.12036 0.125 1.64988 0.309374 1.30299 0.637563C0.956108 0.965752 0.76123 1.41087 0.76123 1.875V2.05088L6.31031 4.878L11.8594 2.05175V1.875C11.8594 1.41087 11.6645 0.965752 11.3176 0.637563C10.9708 0.309374 10.5003 0.125 10.0097 0.125H2.61093Z"
              fill="black"
            />
            <path
              d="M11.8594 3.04492L6.5295 5.76005C6.46214 5.79436 6.38682 5.81232 6.31031 5.81232C6.23381 5.81232 6.15849 5.79436 6.09113 5.76005L0.76123 3.04492V7.12505C0.76123 7.58917 0.956108 8.03429 1.30299 8.36248C1.64988 8.69067 2.12036 8.87505 2.61093 8.87505H10.0097C10.5003 8.87505 10.9708 8.69067 11.3176 8.36248C11.6645 8.03429 11.8594 7.58917 11.8594 7.12505V3.04492Z"
              fill="black"
            />
          </svg>
          <span>{{ company.email }}</span>
        </div>

        <div class="contact-item" v-if="company.phoneNumber">
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.31293 8.89752L9.34876 7.91752C9.48827 7.78716 9.66479 7.69793 9.85687 7.66065C10.049 7.62337 10.2483 7.63965 10.4308 7.70752L11.6932 8.18439C11.8777 8.25521 12.0358 8.3761 12.1477 8.53181C12.2596 8.68753 12.3203 8.8711 12.3221 9.05939V11.2469C12.3211 11.375 12.2926 11.5015 12.2385 11.619C12.1843 11.7364 12.1057 11.8422 12.0071 11.9301C11.9086 12.018 11.7924 12.0861 11.6653 12.1303C11.5382 12.1746 11.403 12.194 11.2678 12.1875C2.42165 11.6669 0.636696 4.57939 0.299127 1.86689C0.283457 1.73369 0.297774 1.59886 0.341137 1.47127C0.384501 1.34367 0.455927 1.22621 0.550717 1.12661C0.645507 1.02702 0.761513 0.947532 0.891102 0.893396C1.02069 0.839259 1.16093 0.811695 1.30259 0.812518H3.53609C3.7354 0.813076 3.92998 0.870035 4.09479 0.976068C4.25961 1.0821 4.38712 1.23236 4.46094 1.40752L4.96498 2.60189C5.03909 2.77386 5.05799 2.96267 5.01934 3.14475C4.98069 3.32682 4.88619 3.49408 4.74764 3.62564L3.71181 4.60564C3.71181 4.60564 4.30834 8.42502 8.31293 8.89752Z"
              fill="black"
            />
          </svg>
          <span>{{ company.phoneNumber }}</span>
        </div>

        <div class="contact-item" v-if="company.mobileNumber">
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.31293 8.89752L9.34876 7.91752C9.48827 7.78716 9.66479 7.69793 9.85687 7.66065C10.049 7.62337 10.2483 7.63965 10.4308 7.70752L11.6932 8.18439C11.8777 8.25521 12.0358 8.3761 12.1477 8.53181C12.2596 8.68753 12.3203 8.8711 12.3221 9.05939V11.2469C12.3211 11.375 12.2926 11.5015 12.2385 11.619C12.1843 11.7364 12.1057 11.8422 12.0071 11.9301C11.9086 12.018 11.7924 12.0861 11.6653 12.1303C11.5382 12.1746 11.403 12.194 11.2678 12.1875C2.42165 11.6669 0.636696 4.57939 0.299127 1.86689C0.283457 1.73369 0.297774 1.59886 0.341137 1.47127C0.384501 1.34367 0.455927 1.22621 0.550717 1.12661C0.645507 1.02702 0.761513 0.947532 0.891102 0.893396C1.02069 0.839259 1.16093 0.811695 1.30259 0.812518H3.53609C3.7354 0.813076 3.92998 0.870035 4.09479 0.976068C4.25961 1.0821 4.38712 1.23236 4.46094 1.40752L4.96498 2.60189C5.03909 2.77386 5.05799 2.96267 5.01934 3.14475C4.98069 3.32682 4.88619 3.49408 4.74764 3.62564L3.71181 4.60564C3.71181 4.60564 4.30834 8.42502 8.31293 8.89752Z"
              fill="black"
            />
          </svg>
          <span>{{ company.mobileNumber }}</span>
        </div>

        <div class="contact-item" v-if="company.location">
          <svg
            width="11"
            height="13"
            viewBox="0 0 11 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.31045 0.666992C4.01077 0.666931 2.76358 1.15218 1.83995 2.01729C0.916333 2.88239 0.390927 4.05742 0.37793 5.28699C0.37793 8.48366 4.72471 12.042 4.90968 12.1937C5.02136 12.284 5.16349 12.3337 5.31045 12.3337C5.45741 12.3337 5.59954 12.284 5.71122 12.1937C5.92701 12.042 10.243 8.48366 10.243 5.28699C10.23 4.05742 9.70457 2.88239 8.78094 2.01729C7.85732 1.15218 6.61013 0.666931 5.31045 0.666992ZM5.31045 7.08366C4.88364 7.08366 4.46642 6.96392 4.11154 6.73958C3.75666 6.51523 3.48007 6.19637 3.31674 5.8233C3.15341 5.45024 3.11067 5.03973 3.19394 4.64368C3.2772 4.24764 3.48273 3.88385 3.78453 3.59832C4.08633 3.31278 4.47084 3.11833 4.88945 3.03956C5.30805 2.96078 5.74195 3.00121 6.13627 3.15574C6.53059 3.31027 6.86762 3.57195 7.10474 3.9077C7.34186 4.24345 7.46843 4.63819 7.46843 5.04199C7.46843 5.58348 7.24107 6.10278 6.83637 6.48567C6.43167 6.86856 5.88278 7.08366 5.31045 7.08366Z"
              fill="black"
            />
          </svg>

          <span>{{ company.location.address }}</span>
        </div>
      </VsaContent>
    </VsaItem>
  </VsaList>
</template>

<script>
import CarrotDownWide from '@/components/Svgs/Carrots/CarrotDownWide';
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon,
} from 'vue-simple-accordion';

export default {
  components: {
    CarrotDownWide,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-item {
  span {
    display: inline-block;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.working-hours-table {
  label {
    margin-left: 8px;
    font-weight: 500;
  }

  .table-row {
    padding: 4px 8px;
  }
}
</style>
