<template>
  <div class="products-company" v-if="company">
    <div class="header">
      <div class="slider">
        <VueAgile :nav-buttons="false">
          <!-- <div class="slide" v-for="img in [company.profileImg]" :key="img"> -->
          <!-- <img :src="img" /> -->
          <!-- </div> -->

          <div class="slide">
            <img :src="company.avatar" />
          </div>
        </VueAgile>
      </div>
    </div>

    <div class="content">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="company-info">
            <div class="name">{{ company.companyName }}</div>

            <div class="address">
              {{ company.location.city }},
              {{ company.location.address }}
            </div>

            <div class="location address">
              <MapPinIcon />
              {{
                $t('enduser.services.nKmFromYou', {
                  n: company.distance || '?',
                })
              }}
            </div>

            <div class="reviews mt-4">
              <div class="stars">
                <!-- <Rating :stars="5" :active-stars="4" active-color="#FFD600" />
                <div class="impressions">(57 impressions)</div> -->
              </div>

              <div class="price font-weight-bold" v-if="priceFrom">
                Praise ab <span>{{ priceFrom | EUR }}</span>
              </div>
            </div>
          </div>

          <div class="company-details my-4">
            <ProductsCompanyInfo :company="company" />
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="products-list">
            <ProductsWidget
              v-model="selectedProducts"
              :company-id="company.id"
              :products="company.products"
            />
          </div>
        </div>
      </div>
    </div>

    <ResponseAlert
      v-if="$store.getters.errorResponse.action == 'addToCart'"
      :response="$store.getters.errorResponse.data"
      class="mt-3"
    ></ResponseAlert>

    <div class="d-flex">
      <ButtonElement :orange-secondary="true" anchor="/products/categories">
        {{ $t('buttons.cancel') }}
      </ButtonElement>
      <ButtonElement
        v-if="company.type == 'retailer'"
        :orange-secondary="true"
        :anchor="{
          name: 'RetailerBookAppointment',
        }"
        class="ml-3"
      >
        {{ 'Termin buchen' }}
      </ButtonElement>
      <ButtonElement
        class="ml-auto"
        :orange="true"
        :disabled="!selectedProducts.length"
        :loading="$store.getters.loading"
        @click="addToCart"
      >
        {{ $t('buttons.addToCart') }}
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';
// import Rating from '@/components/Rating/StarRating';
import ProductsWidget from '@/components/Products/ProductsWidget';
import ProductsCompanyInfo from '@/components/Products/ProductsCompanyInfo';
import MapPinIcon from '@/components/Svgs/MapPinIcon';

export default {
  components: {
    ProductsWidget,
    ProductsCompanyInfo,
    VueAgile,
    MapPinIcon,
    // Rating,
  },

  data() {
    return {
      selectedProducts: [],
    };
  },

  computed: {
    company() {
      return this.$store.getters.companyWithProducts;
    },
    priceFrom() {
      if (!this.company || !this.company.products.length) {
        return null;
      }

      let minPrice = parseFloat(this.company.products[0].price);

      this.company.products.forEach(p => {
        const price = parseFloat(p.price);

        if (price < minPrice) {
          minPrice = price;
        }
      });

      return minPrice;
    },
  },

  created() {
    this.$store
      .dispatch('fetchCompanyWithProducts', this.$route.params.id)
      .catch(() => {
        alert(404);
      });
  },

  methods: {
    addToCart() {
      // Filter out products that are already in cart
      const payload = this.selectedProducts.filter(i => {
        return !this.$store.getters.cartItems.find(ci => ci.item.id == i.id);
      });

      if (!payload.length) {
        this.$router.push({ name: 'Cart' });
        return;
      }

      this.$store.dispatch('addToCart', payload).then(() => {
        this.$router.push({ name: 'Cart' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.company-info {
  padding: 20px;
  background: #f6f6f6;
  border-radius: 10px;

  .name {
    font-weight: bold;
    font-size: 24px;
    color: #e58413;
  }
  .address {
    font-weight: normal;
    font-size: 16px;
    color: #999999;
    margin-top: 10px;
  }
  .reviews {
    display: flex;
    justify-content: space-between;
  }
  .stars {
    display: flex;
    align-items: center;

    .impressions {
      font-weight: normal;
      font-size: 14px;
      color: #999999;
    }
  }
  .price {
    font-weight: normal;
    font-size: 14px;
    color: #999999;
    span {
      font-weight: bold;
      font-size: 20px;
      color: #2b2b2b;
    }
  }
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  button {
    width: 33%;
    justify-content: center;
  }
}
.slider {
  margin-bottom: 2rem;

  .slide,
  .agile__slide {
    height: 335px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
